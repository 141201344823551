import React from 'react'
import classNames from 'classnames'

import './Icons.css'

const Icon = ({ className, type = 'placeholder', small, fill, ...rest }) => {
  return (
    <span
      {...rest}
      className={classNames('icon', className, `icon-${type}`, {
        'icon-small': small,
      })}
      style={{ ...rest.style, backgroundColor: fill }}
    />
  )
}

export default Icon
