import * as ReactNative from 'react-native-web'
import {
  ImagePropTypes,
  ViewPropTypes,
  TextPropTypes,
  TextInputPropTypes,
} from 'deprecated-react-native-prop-types'

ReactNative.Image.propTypes = ImagePropTypes
ReactNative.View.propTypes = ViewPropTypes
ReactNative.Text.propTypes = TextPropTypes
ReactNative.TextInput.propTypes = TextInputPropTypes
