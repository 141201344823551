import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'

import { fetchApp, fetchAppId } from '../../utils/io'
import { getPreviewType } from '../../utils/previewType'
import { getAdaloAttributionSettings } from '../../utils/adaloAttribution'
import { MIN_SCREEN_WIDTH_SAFE_FOR_FRAME } from '../../utils/frame'

import Loading from '../Shared/Loading'
import Renderer from '../Renderer'
import Frame from './Frame'
import { WEB, DEFAULT_SIZE } from './SizeMenu'
import AppDetails from './AppDetails'
import AdaloBadge from './AdaloBadge'
import NotFound from './NotFound'
import Stripe from './Stripe'

import './Share.css'
import './WrappedSelect.css'

const Preview = () => {
  const { path } = useParams()
  const { width: windowWidth } = useWindowSize()
  const [app, setApp] = useState(null)
  const [error, setError] = useState(false)

  const getAppId = async () => {
    // in prod the web-preview-proxy adds the app id to the html
    const htmlAppId = document.querySelector('meta[property="adalo:appId"]')
      ?.content

    if (htmlAppId && htmlAppId !== 'APP_ID') {
      return htmlAppId
    }

    // locally or on staging: call backend to resolve app id with subdomain & path
    const host = window.location.host

    try {
      const appId = await fetchAppId(host, path)

      return appId
    } catch (error) {
      console.error('ERROR GETTING APP ID', error)

      // defaulting to appId in the path
      return path
    }
  }

  const requestApp = async () => {
    try {
      const appId = await getAppId()
      const app = await fetchApp(appId)
      setApp(app)
      document.title = app.name
    } catch (error) {
      console.error('ERROR GETTING APP ID', error)
      setError(true)
    }
  }

  useEffect(() => {
    requestApp()
  }, [])

  if (error) {
    return <NotFound />
  }

  if (!app) {
    return <Loading expanded />
  }

  const appId = app.id

  const { attributionComponent } = getAdaloAttributionSettings(
    app,
    window.flags,
    windowWidth
  )

  let size = null
  const previewType = getPreviewType(app)

  if (previewType === 'web') {
    size = size || WEB
  }

  if (size === WEB) {
    const offsetTop = attributionComponent === 'footer' ? 0 : 74

    return (
      <div>
        <div className="web-renderer-wrapper">
          <Renderer app={app} offsetTop={offsetTop} previewType={previewType} />
        </div>
        {attributionComponent === 'badge' && <AdaloBadge />}
      </div>
    )
  }

  if (windowWidth < MIN_SCREEN_WIDTH_SAFE_FOR_FRAME) {
    return (
      <>
        <Renderer
          analytics
          app={app}
          hideAppFooter={attributionComponent !== 'footer'}
          size={DEFAULT_SIZE}
          previewType={previewType}
        />
      </>
    )
  }

  size = size || DEFAULT_SIZE

  return (
    <React.Fragment>
      <Stripe branding={app.branding} className="top-stripe" />
      <div className="preview">
        <AppDetails app={app} />
        <Frame appId={appId} size={size} />
      </div>
      {attributionComponent === 'badge' && <AdaloBadge />}
    </React.Fragment>
  )
}

export default Preview
