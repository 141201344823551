import chroma from 'chroma-js'

export const contrastWithBackground = value => {
  if (!value) return null

  return chroma.contrast(value, '#fff') > 2.5 ? '#fff' : '#000'
}

export const hexToRGB = (hex, alpha) => {
  if (!hex) return null

  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) return `rgba(${r}, ${g}, ${b}, ${alpha})`

  return `rgb(${r}, ${g}, ${b})`
}
