import React, { Component } from 'react'
import classNames from 'classnames'

import { shouldPrompt } from 'utils/installPrompt'
import AppIcon from '../Share/Icon'
import './IOSInstallPrompt.css'
import { ReactComponent as ShareButton } from './share.svg'

const isInStandaloneMode = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone ||
  document.referrer.includes('android-app://')

const isIOSSafari = () => {
  const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  return ios && !isInStandaloneMode()
}

export default class IOSInstallPrompt extends Component {
  state = { hidden: false }

  handleClose = () => {
    this.setState({ hidden: true })
  }

  render() {
    const { hidden } = this.state
    const { app } = this.props

    if (!isIOSSafari() || !shouldPrompt(app)) {
      return null
    }

    const { id, name } = app

    return (
      <div className={classNames('ios-install-prompt-wrapper', { hidden })}>
        <div className="ios-install-prompt-modal">
          <div className="ios-install-prompt-close" onClick={this.handleClose}>
            ×
          </div>
          <div className="ios-install-prompt-content">
            <AppIcon appId={id} name={name} />
            <h1>Install {name}</h1>
            <p>
              Install this application on your home screen to easily access it
              on the go.
            </p>
          </div>
          <div className="ios-install-prompt-cta">
            Just tap
            <ShareButton />
            then "Add to Home Screen"
          </div>
        </div>
      </div>
    )
  }
}
