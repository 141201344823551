import React from 'react'
import classNames from 'classnames'

import { contrastWithBackground, hexToRGB } from '../../../utils/colors'

import Icon from '../Icon'
import Loading from '../Loading'

import './Button.css'

const Button = ({
  children,
  content,
  className,
  backgroundColor,
  icon,
  text,
  loading,
  disabled,
  ...rest
}) => {
  const color = contrastWithBackground(backgroundColor)
  const bgOpaque = hexToRGB(backgroundColor, 0.5)

  backgroundColor = disabled || loading ? bgOpaque : backgroundColor

  const styles = backgroundColor ? { backgroundColor, color } : null

  return (
    <button
      {...rest}
      className={classNames(
        'btn',
        {
          'btn-icon': icon,
          'btn-text': text,
          'btn-loading': loading,
        },
        className
      )}
      style={styles}
      disabled={disabled}
    >
      {icon ? <Icon type={icon} /> : content || children}
      {loading ? (
        <div className="btn-loading-loader">
          <Loading small />
        </div>
      ) : null}
    </button>
  )
}

export default Button
