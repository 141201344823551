import React from 'react'
import classNames from 'classnames'
import './Stripe.css'

export default ({ branding, className }) => {
  const primary = branding && branding.primary
  const secondary = branding && branding.secondary

  return (
    <div className={classNames('color-stripe', className)}>
      <StripeBar color={primary} />
      <StripeBar color={secondary} />
      <StripeBar color={primary} />
    </div>
  )
}

export const StripeBar = ({ color }) => {
  return <div className="color-stripe-bar" style={{ backgroundColor: color }} />
}
