import React from 'react'
import classNames from 'classnames'

import './Loading.css'

const Loading = ({ expanded, small }) => (
  <div
    className={classNames('loading', {
      'loading-expanded': expanded,
      'loading-small': small,
    })}
  >
    <div className="loading-icon" />
  </div>
)

export default Loading
