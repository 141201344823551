import { isInIframe, MIN_SCREEN_WIDTH_SAFE_FOR_FRAME } from './frame'
import { getPreviewType } from './previewType'

/**
 * Returns what kind of adalo attribution we should show
 *
 * The rules for it are:
 * - If the app is from a free organization, we should always show some kind of Adalo attribution
 * - If the `hasFreePlanWebRuntimeBanner` has been turned on for the app, or the org has the web setting showAdaloAttribution turned on, we should show the Adalo attribution
 * - If we're showing the device frame for a mobile app, we should show the badge as attribution
 * - If we're displaying the full app, outside of a frame, we should always use the footer
 *
 * @param {import("types").App} app
 * @param {object | undefined} flags
 * @param {number | undefined} width
 * @returns {{
 *  showAdaloAttribution: boolean,
 *  attributionComponent: string | undefined
 *  showCloningWithoutAdaloBadge: boolean | undefined
 *  cloningEnabled: boolean | undefined
 * }}
 */
export const getAdaloAttributionSettings = (app, flags, width) => {
  const { showBranding, primaryPlatform } = app
  const previewType = getPreviewType(app)

  // All free apps should always show the Adalo attribution
  // Flag also enables showing the attribution for any maker on any plan if we need to
  const shouldShowForOrg =
    flags?.hasFreePlanWebRuntimeBanner || app?.Organization?.planType === 'free'

  const showAttributionOnMobile = showBranding ?? true
  const showAttributionWebSetting =
    app?.webSettings?.showAdaloAttribution ?? false

  // Mobile apps keep using legacy 'showBranding' property
  const shouldShowInPlatform =
    primaryPlatform === 'mobile'
      ? showAttributionOnMobile
      : showAttributionWebSetting

  const inIframe = isInIframe()

  const cloningEnabled = app?.visibility
  const showAdaloAttribution =
    !inIframe && (shouldShowForOrg || shouldShowInPlatform || cloningEnabled)

  // The attribution component should always be the
  let attributionComponent
  let showCloningWithoutAdaloBadge

  if (showAdaloAttribution) {
    attributionComponent = 'footer'

    showCloningWithoutAdaloBadge =
      cloningEnabled && !(shouldShowForOrg || shouldShowInPlatform)

    const windowWidth = width ?? window.innerWidth
    const showingDeviceFrame =
      previewType === 'mobile' && windowWidth >= MIN_SCREEN_WIDTH_SAFE_FOR_FRAME

    if (showingDeviceFrame && !showCloningWithoutAdaloBadge) {
      // Only show the badge if we're showing the device frame
      // Otherwise, always show the footer
      attributionComponent = 'badge'
    }
  }

  return {
    showAdaloAttribution,
    attributionComponent,
    showCloningWithoutAdaloBadge,
    cloningEnabled,
  }
}
