import React from 'react'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.us'

import './Input.css'

const Input = ({ prepend, append, label, id, ...rest }) => {
  const { options } = rest
  const RenderedInput = options ? Cleave : 'input'

  return (
    <div className="input-wrapper">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="input-group">
        {prepend && <div className="input-group-prepend">{prepend}</div>}
        <RenderedInput {...rest} id={id} />
        {append && <div className="input-group-append">{append}</div>}
      </div>
    </div>
  )
}

export default Input
