import React from 'react'

const AdaloBadge = () => (
  <a
    href="https://adalo.com"
    target="_blank"
    rel="noopener noreferrer"
    className="adalo-share-attribution"
  >
    <span className="logo" alt="Adalo" />
    <h6>Created with Adalo</h6>
  </a>
)

export default AdaloBadge
