import axios from 'axios'

const baseURL = process.env.REACT_APP_COMPONENT_URL
const marketplaceURL = process.env.REACT_APP_COMPONENT_MARKETPLACE
const cache = {}

export const getLibraryDependencies = async app => {
  const { librariesUsed = [], OrganizationId, id: appId } = app

  const { data } = await axios.get(`${marketplaceURL}/api/libraries`, {
    params: {
      names: encodeURIComponent(
        JSON.stringify(['@protonapp/material-components', ...librariesUsed])
      ),
      orgId: OrganizationId,
      appId,
    },
  })

  return data
}

// Returns promise
export const loadLibrary = (name, version) => {
  const url = `${baseURL}/${name}/${version}/runtime-${version}.js`

  // Check if already in cache
  if (cache[url]) {
    return cache[url]
  }

  const script = document.createElement('script')
  script.src = url
  script.setAttribute('crossorigin', '')
  document.body.appendChild(script)

  const promise = new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      resolve()
    })

    script.addEventListener('error', () => {
      reject(new Error(`Script could not load: ${url}`))
    })
  })

  cache[url] = promise

  return promise
}

export const loadLibraries = async app => {
  const libraryDependencies = await getLibraryDependencies(app)
  const promises = libraryDependencies.map(({ name, version }) => {
    return loadLibrary(name, version)
  })

  return Promise.allSettled(promises)
}

export const getLibraries = app => {
  return window.protonRuntime || {}
}
