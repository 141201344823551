const hasWebSettings = app => Boolean(app.magicLayout && app.webSettings)

const isValidPreviewType = previewType =>
  ['mobile', 'web'].includes(previewType)

export const getPreviewType = app => {
  const { webSettings, primaryPlatform } = app

  if (webSettings?.layoutMode === 'mobile') {
    return 'mobile'
  }

  if (!hasWebSettings(app)) {
    // Legacy apps
    return primaryPlatform
  }

  const settingsPreviewType = webSettings?.previewType
  // Default to web when invalid/unset
  const previewType = isValidPreviewType(settingsPreviewType)
    ? settingsPreviewType
    : 'web'

  return previewType
}
