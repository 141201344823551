import React, { Component } from 'react'
import classNames from 'classnames'
import MultiSelectMenu from '@protonapp/react-multi-menu'
import deepEqual from 'deep-equal'

import '@protonapp/react-multi-menu/styles.css'

const OPTIONS = [
  {
    label: 'iPhone XS',
    subtitle: '375 × 812',
    value: {
      width: 375,
      height: 812,
      os: 'ios',
      offset_top: 44,
      offset_bottom: 34,
    },
  },
  {
    label: 'iPhone XR',
    subtitle: '414 × 896',
    value: {
      width: 414,
      height: 896,
      os: 'ios',
      offset_top: 44,
      offset_bottom: 34,
    },
  },
  {
    label: 'iPhone 8',
    subtitle: '375 × 667',
    value: { width: 375, height: 667, os: 'ios' },
  },
  {
    label: 'iPhone 8 Plus',
    subtitle: '414 × 736',
    value: { width: 414, height: 736, os: 'ios' },
  },
  {
    label: 'iPhone SE',
    subtitle: '320 × 568',
    value: { width: 320, height: 568, os: 'ios' },
  },
  null,
  {
    label: 'Galaxy S9',
    subtitle: '360 × 740',
    value: { width: 360, height: 740, os: 'android' },
  },
  {
    label: 'Google Pixel 2',
    subtitle: '411 × 731',
    value: { width: 411, height: 731, os: 'android' },
  },
  null,
  {
    label: 'iPad Pro',
    subtitle: '1024 × 1366',
    value: { width: 1024, height: 1366, os: 'ios' },
  },
  {
    label: 'Kindle Fire',
    subtitle: '600 × 1024',
    value: { width: 600, height: 1024, os: 'android' },
  },
]

export const DEFAULT_SIZE = OPTIONS[0].value
export const WEB = 'web'

const WEB_OPTIONS = [
  {
    label: 'Web',
    value: WEB,
  },
  null,
].concat(OPTIONS)

export default class SizeMenu extends Component {
  getOptions() {
    const { includeWeb } = this.props

    return includeWeb ? WEB_OPTIONS : OPTIONS
  }

  render() {
    let { size, onChange, inline } = this.props

    size = size || DEFAULT_SIZE

    return (
      <div
        className={classNames(
          'preview-size-menu',
          inline && 'preview-size-menu-inline'
        )}
      >
        <MultiSelectMenu
          className="wrapped-select white"
          options={this.getOptions()}
          value={size}
          comparator={deepEqual}
          onChange={onChange}
        />
      </div>
    )
  }
}
