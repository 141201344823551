import { useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'

const WEB_RUNTIME_FLAGS = ['hasFreePlanWebRuntimeBanner']

// Used to get web-runtime-specific flags from outside the LaunchDarkly context
const setWebRuntimeFlagsToWindow = ffClient => {
  const flags = ffClient.allFlags()
  const flagKeys = Object.keys(flags)
  window.flags = {}

  flagKeys
    .filter(k => WEB_RUNTIME_FLAGS.includes(k))
    .forEach(key => {
      window.flags[key] = flags[key]
    })
}

const FeatureFlagIdentitySync = ({ app }) => {
  const client = useLDClient()

  const { flagUser } = app || {}

  useEffect(() => {
    if (!client || !app) {
      return
    }

    client.identify(flagUser)
    setWebRuntimeFlagsToWindow(client)
  }, [client, app, flagUser])

  return null
}

export default FeatureFlagIdentitySync
