import React from 'react'

import './ErrorPage.css'

export default function NotFound() {
  return (
    <div className="error-page-wrapper">
      <div className="error-page-content">
        <h1>App Not Found</h1>
        <p>
          Unfortunately we couldn't find the app you're looking for. If this is
          your app, try checking your custom domain settings, and try again.
        </p>
      </div>
    </div>
  )
}
