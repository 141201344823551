/**
 * In the context of the web-runtime application,
 * only show install prompt if:
 * - app has the `show add to home screen` toggle on
 * - app has layout mode set to mobile only
 * - primary platform is mobile (unlikely to hit this case)
 *
 * @param {import("types").App} [app]
 * @returns {boolean}
 */
export const shouldPrompt = app => {
  if (!app) {
    return false
  }

  const hasMagicLayout = Boolean(app.magicLayout)
  const showAddToHomeScreen = Boolean(
    app.webSettings?.showAddToHomeScreen ?? false
  )
  const appLayoutIsMobileOnly = Boolean(
    app.webSettings?.layoutMode === 'mobile'
  )
  const primaryPlatform = app.primaryPlatform

  if (hasMagicLayout) {
    if (showAddToHomeScreen) {
      return true
    }

    if (appLayoutIsMobileOnly) {
      return true
    }
  }

  if (primaryPlatform === 'mobile') {
    return true
  }

  return false
}
