import React from 'react'
import { getAppIconUrl } from '../../utils/io'

export default ({ appId, name }) => (
  <span className="preview-app-icon-wrapper">
    <img
      src={getAppIconUrl(appId, 120)}
      alt={name}
      className="preview-app-icon"
    />
  </span>
)
