import React, { Component } from 'react'
import qs from 'qs'
import SafeAreaInsets from 'safe-area-insets'
import StatusBar from '@protonapp/react-status-bar'
import { isInIframe } from '../utils/frame'

import {
  DATABASE_URL,
  DATABASE_API_URL,
  ASSETS_URL,
  FILE_UPLOADS_URL,
  IMAGE_UPLOADS_URL,
  NOTIFICATIONS_URL,
  WEBNOTIFICATION_VAPID_PUBLIC_KEY,
  WEBNOTIFICATION_FIREBASE_CONFIG,
} from '../config'

import { getAppIconUrl } from '../utils/io'
import { getLibraries } from '../utils/libraries'
import FeatureFlagIdentitySync from '../flags/FeatureFlagIdentitySync'

import NotFound from './Share/NotFound'
import LibraryLoader from './LibraryLoader'
import AdaloFooter, { getAdaloFooterHeight } from './AdaloFooter'
import Runner from './RunnerWrapper'
import IOSInstallPrompt from './IOSInstallPrompt'
import InstallPrompt from './InstallPrompt'

import '@protonapp/react-status-bar/styles.css'
import './Renderer.css'

export default class Renderer extends Component {
  state = {
    authenticationError: false,
    librariesReady: false,
    currentComponentId: null,
    /** @type {import('beforeinstallprompt').BeforeInstallPromptEvent}} */
    installPrompt: null,
  }

  /**
   *
   * @param {import('beforeinstallprompt').BeforeInstallPromptEvent} e
   */
  onBeforeInstallPrompt = e => {
    e.preventDefault()

    if (e.type === 'beforeinstallprompt') {
      this.setState({ installPrompt: e })
    }
  }

  componentDidMount() {
    const { app } = this.props

    if (app) {
      document.title = app.name
    }

    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt)
  }

  componentWillUnmount() {
    window.removeEventListener(
      'beforeinstallprompt',
      this.onBeforeInstallPrompt
    )
  }

  handleLoadLibraries = () => {
    this.setState({ librariesReady: true })
  }

  handleUnauthorized = () => {
    this.setState({ authenticationError: true })
  }

  getParams = () => {
    return qs.parse(window.location.search.substring(1))
  }

  getOS = () => {
    return this.getParams().os
  }

  getLayoutGuides = () => {
    const { size } = this.props
    const inIframe = isInIframe()

    const params = inIframe ? size : this.getParams()

    if (SafeAreaInsets.top > 0) {
      return { top: SafeAreaInsets.top, bottom: 0 }
    }

    const offsetTop = params?.offset_top ?? 0
    const offsetBottom = params?.offset_bottom ?? 0

    const top = +offsetTop
    const bottom = +offsetBottom

    return { top, bottom }
  }

  handleRouteChange = newRoute => {
    this.setState({ currentComponentId: newRoute.componentId })
  }

  renderStatusBar() {
    const { app } = this.props
    const { currentComponentId } = this.state

    if (!isInIframe()) {
      return null
    }

    if (!app || !app.components) {
      return null
    }

    const component = app.components[currentComponentId]
    const layoutGuides = this.getLayoutGuides()

    if (!component) {
      return null
    }

    const { statusBarStyle } = component
    let light = false
    const hasNotch = layoutGuides.top > 20

    if (statusBarStyle === 'hidden') {
      return null
    }

    if (statusBarStyle === 'light') {
      light = true
    }

    return (
      <div className="status-bar-wrapper">
        <StatusBar platform={this.getOS()} light={light} hasNotch={hasNotch} />
      </div>
    )
  }

  render() {
    const { app } = this.props
    const {
      librariesReady,
      authenticationError,
      error,
      installPrompt,
    } = this.state

    let { offsetTop, hideAdaloFooter, previewType } = this.props

    const marginBottom = !hideAdaloFooter
      ? getAdaloFooterHeight(app)
      : undefined

    const { hasNotch } = this.getParams()

    const PREVIEW_BANNER_HEIGHT = 64

    if (error) {
      return <NotFound />
    }

    if (authenticationError) {
      return (
        <div>
          <h1>Unauthorized</h1>
        </div>
      )
    }

    const layoutGuides = this.getLayoutGuides()
    const libraries = getLibraries(app)

    if (hasNotch) {
      offsetTop = PREVIEW_BANNER_HEIGHT
    }

    return (
      <React.Fragment>
        <FeatureFlagIdentitySync app={app} />
        <LibraryLoader app={app} onLoad={this.handleLoadLibraries} />
        <Runner
          app={librariesReady ? app : null}
          baseURL={DATABASE_URL}
          baseAPIURL={DATABASE_API_URL}
          assetsBaseURL={ASSETS_URL}
          fileUploadsBaseURL={FILE_UPLOADS_URL}
          imageUploadsBaseURL={IMAGE_UPLOADS_URL}
          notificationsURL={NOTIFICATIONS_URL}
          libraries={libraries}
          offsetTop={offsetTop}
          previewType={previewType}
          transitionStyle={this.getOS()}
          layoutGuides={layoutGuides}
          onRoute={this.handleRouteChange}
          marginBottom={marginBottom}
          webNotificationConfig={{
            vapidPublicKey: WEBNOTIFICATION_VAPID_PUBLIC_KEY,
            firebaseConfig: WEBNOTIFICATION_FIREBASE_CONFIG,
            appIcon: getAppIconUrl(app.id, 128),
          }}
        />
        {app ? this.renderStatusBar() : null}
        {!hideAdaloFooter && <AdaloFooter app={app} />}
        {installPrompt !== null && (
          <InstallPrompt app={app} deferredPrompt={installPrompt} />
        )}
        <IOSInstallPrompt app={app} />
      </React.Fragment>
    )
  }
}
