import React from 'react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { getAdaloAttributionSettings } from 'utils/adaloAttribution'
import { FRONTEND_URL } from '../config'

// From App.css
export const APP_FOOTER_HEIGHT = 74

export const getAdaloFooterHeight = app => {
  const shouldShow =
    getAdaloAttributionSettings(app, window.flags)?.attributionComponent ===
    'footer'

  if (shouldShow) {
    return APP_FOOTER_HEIGHT
  }

  return 0
}

function AdaloFooter(props) {
  const { app, flags } = props

  if (!app) {
    return null
  }

  const attributionSettings = getAdaloAttributionSettings(app, flags)
  const {
    attributionComponent,
    showCloningWithoutAdaloBadge,
    cloningEnabled,
  } = attributionSettings
  const shouldShowFooter = attributionComponent === 'footer'

  if (!shouldShowFooter) {
    return null
  }

  const cloneURL = `${FRONTEND_URL}/apps/${app.id}/screens/${app.visibility}`

  return (
    <div className="web-runtime-footer">
      <div>
        {cloningEnabled && (
          <div className="details-footer-clone-button-wrapper">
            <a className="details-footer-clone-button" href={cloneURL}>
              Clone App
            </a>
          </div>
        )}
      </div>
      {!showCloningWithoutAdaloBadge && (
        <a
          href="https://adalo.com"
          target="_blank"
          rel="noopener noreferrer"
          className="preview-proton-attribution"
        >
          <span className="logo" alt="Adalo" />
          <h6>Created with Adalo</h6>
        </a>
      )}
    </div>
  )
}

export default withLDConsumer()(AdaloFooter)
