import * as Sentry from '@sentry/react'
import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { initTracing } from '@protonapp/proton-runner'

import Share from './Share'

import './App.css'

const routerHistory = createBrowserHistory()

// This higher-order component correctly formats Sentry transaction names for routes.
const SentryRoute = Sentry.withSentryRouting(Route)
initTracing(process.env.REACT_APP_SENTRY_DSN, {
  routingInstrumentation: Sentry.reactRouterV5Instrumentation(routerHistory),
})

class App extends Component {
  render() {
    return (
      <Router history={routerHistory}>
        <div className="app">
          <Switch>
            <SentryRoute exact path="/" component={Share} />
            <SentryRoute path="/:path" component={Share} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
